import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import InputAdornment from "@material-ui/core/InputAdornment";
import LockIcon from "@material-ui/icons/Lock";
import EmailIcon from "@material-ui/icons/Email";
import { useForm } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import PersonIcon from "@material-ui/icons/Person";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import EventIcon from "@material-ui/icons/Event";
//import UserPaymentMethodsAdd from "../UserProfile/UserPaymentMethodsAdd";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import axios from "axios";
import LinearProgress from "@material-ui/core/LinearProgress";
import { setAmountToPay } from "../../../../data-store/actions/vehicle-actions";
import { Paper } from "@material-ui/core";
import LogoPaguelofacil from "./LogoPáguelofacil.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "black",
  },
  paper: {
    padding: "20px",
    backgroundColor: "#283747",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function CardPayment(props) {
  const { callback, locationId, businessId, vehicleId, card } = props;
  const dispatch = useDispatch();

  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, seterrorMessage] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const amountToPay = useSelector((state) => state.vehicle.amountToPay);
  const dateToPay = useSelector((state) => state.vehicle.dateToPay);
  const amountPayed = useSelector((state) => state.vehicle.amountPayed);
  const statusPayment = useSelector((state) => state.vehicle.statusPayment);

  function onSubmit(data) {
    // setCardNumber(data.cardNumber);
    // setCardOwner(data.cardOwner);
    setSending(true);
    seterrorMessage(false);
    // console.log("data", data);
    // console.log("data", cardNumber);
    // console.log("data", sending);

    console.log("tokenizar", data);
    sendPaymetPagueloFacil(data);
  }

  function sendPaymetPagueloFacil(data) {
    const cardNumber = data.cardNumber;
    const cardOwnerName = data.cardOwnerName;
    const cardOwnerLastName = data.cardOwnerLastName;
    const email = data.email;
    const phone = data.phone;
    const cardType = Array.from(cardNumber)[0] == 5 ? "MASTERCARD" : "VISA";

    var jsonDatos = {
      businessId: businessId,
      locationId: locationId,
      vehicleId: vehicleId,
      date: dateToPay,
      payload: {
        amount: amountToPay, //El monto o valor total de la transacción a realizar. NO PONER
        taxAmount: 0,
        email: email,
        phone: phone,
        address: "", //String MaxLength:100 Dirección del Tarjeta,
        concept: "Pago servicio de valet parking", //MaxLength:150 ;Es la descripción o el motivo de la transacción en proceso
        description: "Card" + card + "id:" + vehicleId, //MaxLength:150 ;Es la descripción o el motivo de la transacción en proceso
        lang: "ES", //EN
        cardInformation: {
          cardNumber: cardNumber,
          expMonth: data.month, //Mes de expiración de la tarjeta, siempre 2 dígitos
          expYear: data.year, //Numeric Ej.:02 Año de expiración de la tarjeta.
          cvv: data.securityCode, //Código de Seguridad de la tarjeta Numeric MaxLength:3
          firstName: cardOwnerName, //String MaxLength:25 Nombre del tarjeta habiente
          lastName: cardOwnerLastName, //String MaxLength:25 Apellido del Tarjeta habiente
          cardType: cardType,
        },
      },
    };
    console.log("payment", jsonDatos);

    console.log(jsonDatos);

    var headers = {
      headers: { "Access-Control-Allow-Origin": true },
    };

    axios
      .post(
        "https://us-central1-softicade.cloudfunctions.net/setPagueloFacilPayment",
        jsonDatos,
        headers
      )
      .then((res) => {
        console.log("respuesta", res);

        setSending(false);

        if (res.data.status === 1) {
          console.log("respuesta", "Aprobada");
          dispatch(
            setAmountToPay(
              0,
              jsonDatos.payload.amount,
              res.data.status,
              dateToPay
            )
          );
        } else {
          seterrorMessage("Tarjeta declinada");

          dispatch(
            setAmountToPay(
              jsonDatos.payload.amount,
              0,
              res.data.status,
              dateToPay
            )
          );
        }
      })
      .catch((error) => {
        console.log("respuesta", JSON.stringify(error));
        setSending(false);
        seterrorMessage("Error procesando el pago");
        var errorTexto = "";
        if (error.responseJSON !== undefined) {
          for (var i in error.responseJSON.details) {
            errorTexto =
              errorTexto + error.responseJSON.details[i].message + " - ";
          }
        }
      });
  }

  if (!showForm) {
    return (
      <Grid
        container
        direction="row"
        style={{
          height: "auto",
          width: "100%",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          alignItems={"left"}
          style={{ margin: "40px" }}
        >
          <Button
            onClick={() => setShowForm(true)}
            color="primary"
            variant="contained"
            style={{ padding: 30, width: 400, fontSize: 10 }}
          >
            <Grid container direction="row">
              <Grid item xs={12} sm={12}>
                Pagar con Tarjeta bancaria
              </Grid>
              <Grid item xs={12} sm={12}>
                <img src={LogoPaguelofacil} alt="banner" width={"50%"} />
              </Grid>
            </Grid>
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      direction="row"
      style={{
        height: "auto",
        width: "100%",
      }}
    >
      <Grid item xs={12} sm={12} alignItems={"left"} style={{ margin: "40px" }}>
        <Button
          onClick={() => setShowForm(false)}
          color="primary"
          variant="contained"
          style={{ padding: 30, width: 400, fontSize: 20 }}
        >
          Ocultar formulario de pago
        </Button>

        {amountToPay === 0 && amountPayed > 0 && statusPayment === 1 && (
          <Typography
            color="primary"
            variant="h4"
            style={{ marginTop: 40, padding: 10 }}
          >
            Pago éxitoso, diríjase a retirar su vehículo.
          </Typography>
        )}
      </Grid>
      {amountToPay > 0 && (
        <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          style={{ width: "100%" }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            alignItems={"left"}
            style={{ margin: "40px" }}
          >
            <Typography color="primary" variant="h3" style={{ marginTop: 40 }}>
              Datos del propietario de la tarjeta
            </Typography>
            <Typography color="primary" variant="h5">
              Nombre
            </Typography>
            <TextField
              style={{ width: "100%" }}
              helperText={errors.cardOwner ? "Campo requerido" : null}
              InputProps={{
                style: {
                  marginBottom: 15,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon style={{}} />
                  </InputAdornment>
                ),
              }}
              defaultValue={""}
              error={Boolean(errors.cardOwner)}
              fullWidth
              inputRef={register({ required: true })}
              name="cardOwnerName"
            />
            <Typography color="primary" variant="h5">
              Apellido
            </Typography>
            <TextField
              style={{ width: "100%" }}
              helperText={errors.cardOwner ? "Campo requerido" : null}
              InputProps={{
                style: {
                  marginBottom: 15,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon style={{}} />
                  </InputAdornment>
                ),
              }}
              defaultValue={""}
              error={Boolean(errors.cardOwner)}
              fullWidth
              inputRef={register({ required: true })}
              name="cardOwnerLastName"
            />
            <Typography color="primary" variant="h5">
              Correo
            </Typography>
            <TextField
              style={{ width: "100%" }}
              helperText={errors.cardOwner ? "Campo requerido" : null}
              InputProps={{
                style: {
                  marginBottom: 15,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon style={{}} />
                  </InputAdornment>
                ),
              }}
              defaultValue={""}
              error={Boolean(errors.cardOwner)}
              fullWidth
              inputRef={register({ required: true })}
              name="email"
            />
            <Typography color="primary" variant="h5">
              Teléfono
            </Typography>
            <TextField
              style={{ width: "100%" }}
              helperText={errors.cardOwner ? "Campo requerido" : null}
              InputProps={{
                style: {
                  marginBottom: 15,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon style={{}} />
                  </InputAdornment>
                ),
              }}
              defaultValue={""}
              error={Boolean(errors.cardOwner)}
              fullWidth
              inputRef={register({ required: true })}
              name="phone"
            />

            <Typography color="primary" variant="h3" style={{ marginTop: 40 }}>
              Datos de la tarjeta
            </Typography>

            <Typography color="primary" variant="h5">
              Número de tarjeta
            </Typography>
            <TextField
              style={{ width: "100%" }}
              helperText={errors.cardNumber ? "Campo requerido" : null}
              InputProps={{
                style: {
                  marginBottom: 15,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <CreditCardIcon style={{}} />
                  </InputAdornment>
                ),
              }}
              error={Boolean(errors.cardNumber)}
              fullWidth
              inputRef={register({
                required: true,
                minLength: 10,
              })}
              name="cardNumber"
            />
            <Typography color="primary" variant="h5">
              Fecha de vencimiento
            </Typography>
            <TextField
              label="Mes"
              style={{ width: "50%" }}
              helperText={errors.month ? "Campo requerido" : null}
              InputProps={{
                style: {
                  marginBottom: 15,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <EventIcon style={{}} />
                  </InputAdornment>
                ),
              }}
              defaultValue={1}
              error={Boolean(errors.month)}
              fullWidth
              inputRef={register({ required: true, min: 1, max: 12 })}
              name="month"
              type="number"
            />
            <TextField
              label="Año"
              style={{ width: "50%" }}
              helperText={errors.year ? "Campo requerido" : null}
              InputProps={{
                style: {
                  marginBottom: 15,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <EventIcon style={{}} />
                  </InputAdornment>
                ),
              }}
              defaultValue={2021}
              error={Boolean(errors.year)}
              fullWidth
              inputRef={register({ required: true, min: 2020, max: 2050 })}
              name="year"
              type="number"
            />
            <Typography color="primary" variant="h5">
              Código de Seguridad
            </Typography>

            <TextField
              label="CVC"
              style={{ width: "100%" }}
              helperText={errors.securityCode ? "Campo requerido" : null}
              InputProps={{
                style: {
                  marginBottom: 15,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <VerifiedUserIcon color="secondary" />
                  </InputAdornment>
                ),
              }}
              defaultValue={""}
              error={Boolean(errors.securityCode)}
              fullWidth
              inputRef={register({ required: true, min: 0, max: 9999 })}
              name="securityCode"
              type="number"
            />
            {sending && (
              <Grid item xs={12} sm={12}>
                <LinearProgress />
              </Grid>
            )}
            {!sending && (
              <Button
                type="submit"
                style={{ width: "100%" }}
                color="primary"
                variant="contained"
              >
                Realizar el pago
              </Button>
            )}

            {errorMessage && (
              <Paper>
                <Typography color="primary" variant="h4">
                  {errorMessage}
                </Typography>
              </Paper>
            )}

            {/* <Grid item xs={12} sm={12} style={{ margin: "20px" }}>
              <Typography color="secondary" variant="p">
                Nota: al completar tu pedido recibirás un correo de confirmación
                con tu compra
              </Typography>
            </Grid> */}
          </Grid>
          <Grid item xs={12} sm={12} style={{ margin: "10px" }}>
            <Typography color="primary" variant="h5">
              Powered by:
            </Typography>

            <img src={LogoPaguelofacil} alt="banner" />
          </Grid>
        </form>
      )}
    </Grid>
  );
}
